import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import SculptureThumbnails from './SculptureThumbnails'

import IconExpand from '../../images/svgs/expand-icon.svg'

export default function SculptureImages( props ) {

	const { images } = props

	const [ state, setState ] = useState({
		mainImage: images[ 0 ],
		currentIndex: 0
	})
	const [ modalActive, setModalActive ] = useState()

	const toggleImageModal = () => {

		if ( !modalActive ) {
			const newScrollPosition = window.pageYOffset

			document.body.style.top = `-${newScrollPosition}px`
			document.body.classList.add('u-body-no-scroll')

			setModalActive( true )
		} else {
			const newScrollPosition = document.body.getBoundingClientRect().top

			document.body.classList.remove('u-body-no-scroll');
			document.body.style.removeProperty('top')

			window.scrollTo(0, Math.abs( newScrollPosition ))
			setModalActive( false )
		}
	}

	return (
		<div className="sculpture-images">
			<div className="sculpture-images__main">
				<div className="sculpture-images__main-container" onClick={ () => toggleImageModal() } onKeyDown={ e => { if ( e.keyCode === 13 || e.keyCode === 32 ) toggleImageModal() } } role="button" tabIndex={ 0 }>
					<Img className="sculpture-images__main-image" fluid={{ ...state.mainImage.imageFile.childImageSharp.fluid, aspectRatio: 1/1 }} />
					<div className="sculpture-images__expand">Click to expand <IconExpand className="sculpture-images__expand-icon" /></div>
				</div>
			</div>

			<SculptureThumbnails images={ images } state={ state } setState={ setState } modalActive={ modalActive } toggleImageModal={ toggleImageModal } />
		</div>
	)
}

SculptureImages.propTypes = {
	images: PropTypes.array
}