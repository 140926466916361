import React, { forwardRef } from 'react'
import Img from "gatsby-image"

const SculptureThumbnail = forwardRef( ( props,ref ) => {

	const { isSelected, image, index, handleThumbnailClick } = props

	return (
		<li className="sculpture-images__slide" ref={ ref }>
			<div className="sculpture-images__thumbnail-wrapper">
				<button
					className={`sculpture-images__thumbnail-container ${ isSelected ? 'active' : '' }`}
					onClick={ () => handleThumbnailClick( index, image ) }>
					<Img className="sculpture-images__thumbnail-image" fluid={{ ...image.imageFile.childImageSharp.fluid, aspectRatio: 1/1 }} />
				</button>
			</div>
		</li>
	)
} )

export default SculptureThumbnail