import React from 'react'

import ProfileCard from '../ProfileCard/ProfileCard'
import SculptureImages from '../SculptureImages/SculptureImages'

export default function Sculpture ( props ) {

	const { sculptureArtist, sculptureImages, sponsors } = props.sculpture

	return (
		<div className="sculpture">

			<div className="sculpture__wrapper wrapper">

				<div className="sculpture__images">
					<SculptureImages images={ sculptureImages } />
				</div>

				<div className="sculpture__info">
					{ sponsors &&
						<>
							{
								sponsors.map( sponsor => (
									<ProfileCard
										isSponsor={ true }
										name={ sponsor.sponsorName }
										image={ sponsor.sponsorImage }
										socials={ sponsor.sponsorSocial }
										key={ sponsor.id }
										website={ sponsor.sponsorWebsite } />
								))
							}
						</>
					}

					{ sculptureArtist &&
						<ProfileCard
							name={ sculptureArtist.artistName }
							image={ sculptureArtist.artistImage }
							socials={ sculptureArtist.artistSocial }
							key={ sculptureArtist.id }
							website={ sculptureArtist.artistWebsite }/>
					}
				</div>
			</div>
		</div>
	)
}
