import React, { useEffect, useState, useRef, useCallback } from 'react'

import SculptureThumbnail from './SculptureThumbnail'

import ArrowRight from '../../images/svgs/arrow-right.svg'
import ArrowLeft from '../../images/svgs/arrow-left.svg'
import SculptureModal from './SculptureModal';

export default function SculptureThumbnails ( props ) {

	const RESIZE_DEBOUNCE = 150;
	const thumbnailsRef = useRef()

	const { images, state, setState, modalActive, toggleImageModal } = props

	const [ slideWidth, setSlideWidth ] = useState()
	const [ activeSlide, setActiveSlide ] = useState(0)
	const [ slidePos, setSlidePos ] = useState( state.currentIndex )
	const totalImages = images.length
	const itemsPerPage = 3

	useEffect( () => {

		if ( thumbnailsRef ) {
			setSlideWidth( thumbnailsRef.current.offsetWidth )
		}

		// timeoutId for debounce mechanism
		let timeoutId = null;
		const resizeListener = () => {
			// prevent execution of previous setTimeout
			clearTimeout( timeoutId );
			// change width from the state object after 150 milliseconds
			timeoutId = setTimeout( () => setSlideWidth( thumbnailsRef.current.offsetWidth ), RESIZE_DEBOUNCE );
			// on resize reset slider
			setActiveSlide( 0 )
			setSlidePos( 0 )
			setState({ mainImage: images[ 0 ], currentIndex: 0 })
		};
		
		// set resize listener
		window.addEventListener( 'resize', resizeListener );

		// clean up function
		return () => {
			// remove resize listener
			window.removeEventListener( 'resize', resizeListener );
		};

	}, [ setState, images ] )

	const scrollNext = useCallback(() => {
		const currentIndex = state.currentIndex + 1
		const slidesLeft = totalImages - currentIndex

		if ( currentIndex + 1 >= itemsPerPage && slidesLeft  > 1 ) {
			const slidesToScroll = currentIndex + 1 - itemsPerPage
			setSlidePos( ( slidesToScroll + 1 ) * slideWidth )
			setActiveSlide( currentIndex + 1 )
		}

		setState({
			mainImage: images[ state.currentIndex + 1 ],
			currentIndex: state.currentIndex + 1
		})
	} , [ images, state, setState, slideWidth, totalImages ])

	const scrollPrev = useCallback(() => {
		const currentIndex = state.currentIndex

		if ( activeSlide - currentIndex >= 1 ) {
			const slidesToScroll = slidePos / slideWidth
			setSlidePos( ( slidesToScroll - 1 ) * slideWidth )
			setActiveSlide( currentIndex + 1 )
		}

		setState({
			mainImage: images[ state.currentIndex - 1 ],
			currentIndex: state.currentIndex - 1
		})
	}, [ images, state, setState, slideWidth, activeSlide, slidePos ] )

	const handleThumbnailClick = ( index, image ) => {
		const slidesLeft = totalImages - index

		if ( index + 1 >= itemsPerPage && slidesLeft > 1 && index > state.currentIndex ) { // Next
			const slidesToScroll = index + 1 - itemsPerPage
			setSlidePos( ( slidesToScroll + 1 ) * slideWidth )
			setActiveSlide( index + 1 )
		} else if ( slidesLeft - index >= 1 ) { // Previous
			const slidesToScroll = slidePos / slideWidth
			setSlidePos( ( slidesToScroll - 1 ) * slideWidth )
			setActiveSlide( index + 1 )
		}

		setState({ mainImage: image, currentIndex: index })
	}

	const isSlideSelected = slideIndex => slideIndex === state.currentIndex

	useEffect(() => {

		// If the user presses the escape key, close the modal
		const handleUserKeyPress = e => {

			switch ( e.keyCode ) {
				case 37:
					if ( state.currentIndex ) { scrollPrev() }
					break
				case 39:
					if ( state.currentIndex + 1 !== totalImages ) { scrollNext() }
					break
				default:
					return
			}
		}

		window.addEventListener( 'keydown', handleUserKeyPress )

		return () => {
			window.removeEventListener( 'keydown', handleUserKeyPress )
		}

	}, [ state, totalImages, scrollPrev, scrollNext ])

	return (
		<>
			<div className="sculpture-images__thumbnails-wrapper">

				<div className="sculpture-images__container">
					<div className="sculpture-images__wrapper">
						<ul
							className={`sculpture-images__inner-wrapper block-list-3 ${ totalImages < itemsPerPage ? 'sculpture-images__inner-wrapper--center' : '' }`}
							style={{ left: `-${ slidePos }px` }}>
								{ images.map( ( image, index ) => (
									<SculptureThumbnail
										image={ image }
										key={ index }
										ref={ thumbnailsRef }
										isSelected={ isSlideSelected( index ) }
										index={ index }
										handleThumbnailClick={ handleThumbnailClick } />
								) ) }
						</ul>
					</div>
				</div>

				<ArrowLeft
					className={`sculpture-images__button sculpture-images__button--prev ${ !state.currentIndex ? 'disabled' : '' }`}
					onClick={ () => scrollPrev() }
					/>

				<ArrowRight
					className={`sculpture-images__button sculpture-images__button--next ${ state.currentIndex + 1 === totalImages ? 'disabled' : '' }`}
					onClick={ () => scrollNext() }
					/>
			</div>

			<SculptureModal state={ state } totalImages={ totalImages } scrollPrev={ scrollPrev } scrollNext={ scrollNext } modalActive={ modalActive } toggleImageModal={ toggleImageModal } />
		</>
	)
}