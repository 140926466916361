import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import Sculpture from '../components/Sculpture/Sculpture'
import PageHeader from '../components/PageHeader/PageHeader'

const sculpture = ( { data: { strapi: { sculpture } }, location } ) => {

	return (
		<>
			<SEO title={ `${ sculpture.sculptureName } | Sculpture` } pathname={ location.pathname } />

			<PageHeader heading={ sculpture.sculptureName } />

			<Sculpture sculpture={ sculpture } />
		</>
	)
}

export default sculpture

export const query = graphql`
	query sculptureQuery($id: ID!) {
		strapi {
			sculpture( id: $id ) {
				id
				sculptureName
				sculptureArtist {
					artistName
					artistWebsite
					artistSocial {
						id
						social
						socialLink
					}
					artistImage {
						url
						id
						imageFile {
							childImageSharp {
								fluid(maxWidth: 1000, maxHeight: 1000) {
									...GatsbyImageSharpFluid_withWebp_noBase64
								}
							}
						}
					}
				}
				sponsors { 
					id
					sponsorName
					sponsorWebsite
					sponsorSocial {
						id
						social
						socialLink
					}
					sponsorImage {
						url
						id
						imageFile {
							childImageSharp {
								fluid(maxHeight: 1000) {
									...GatsbyImageSharpFluid_withWebp_noBase64
								}
							}
						}
					}
				}
				sculptureImages {
					url
					id
					imageFile {
						childImageSharp {
							fluid(maxHeight: 1000) {
								...GatsbyImageSharpFluid_withWebp_noBase64
							}
						}
					}
				}
			}
		}
	}
`