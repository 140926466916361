import React from 'react';
import Img from "gatsby-image"

import IconFacebook from '../../images/svgs/facebook-icon.svg'
import IconInstagram from '../../images/svgs/instagram-icon.svg'
import IconLinkedin from '../../images/svgs/linkedin-icon.svg'
import IconTwitter from '../../images/svgs/twitter-icon.svg'
import IconWebsite from '../../images/svgs/website-icon.svg'

export default function ProfileCard( props ) {

	const { image, name, socials, website, isSponsor=false } = props

	return (
		<div className="profile-card">
			<h3 className="profile-card__name">{ isSponsor ? 'Sponsored By:' : 'Artist:'}</h3>
			<div className="profile-card__image-container">
				<Img className={`profile-card__image ${ !isSponsor ? 'profile-card__image--artist' : ''}`} fluid={ image.imageFile.childImageSharp.fluid } imgStyle={{ objectFit: "contain" }} />
			</div>
			<div className="profile-card__name">
				{ name }
			</div>

			<div className="profile-card__social">
				{ website && <a href={ website } className="profile-card__link" target="_blank" rel="noreferrer"><IconWebsite className="profile-card__icon" /> </a>}
				{ socials &&
					<>
						{ socials.map( social => (
							<a href={ social.socialLink } className="profile-card__link" target="_blank" rel="noreferrer" key={ social.id }>
								{ social.social === "Instagram" && <IconInstagram className="profile-card__icon" />}
								{ social.social === "Facebook" && <IconFacebook className="profile-card__icon" />}
								{ social.social === "LinkedIn" && <IconLinkedin className="profile-card__icon" />}
								{ social.social === "Twitter" && <IconTwitter className="profile-card__icon" />}
							</a>
							))
						}
					</>
				}
			</div>
		</div>
	);
}
