import React from 'react'
import Img from 'gatsby-image'

import PlusIcon from '../../images/svgs/plus-icon.svg'
import ArrowRight from '../../images/svgs/arrow-right.svg'
import ArrowLeft from '../../images/svgs/arrow-left.svg'
import { useEffect } from 'react'

export default function SculptureModal ( props ) {

	const { state, modalActive, toggleImageModal, scrollNext, scrollPrev, totalImages } = props

	useEffect(() => {

		// If the user presses the escape key, close the modal
		const handleUserKeyPress = e => {

			if ( e.keyCode === 27 && modalActive ) {
				toggleImageModal()
			}
		}

		window.addEventListener( 'keydown', handleUserKeyPress )

		return () => {
			window.removeEventListener( 'keydown', handleUserKeyPress )
		}

	}, [ modalActive, toggleImageModal ])

	return (
		<div className={`sculpture-images__modal ${ modalActive ? 'active' : '' }`}>
			<div className="sculpture-images__modal-container">
				<PlusIcon className="sculpture-images__modal-close" onClick={ () => toggleImageModal() } />
				<Img className="sculpture-images__modal-image" fluid={ state.mainImage.imageFile.childImageSharp.fluid } imgStyle={{ objectFit: "contain" }} />

				<ArrowLeft
					className={`sculpture-images__modal-button sculpture-images__modal-button--prev ${ !state.currentIndex ? 'disabled' : '' }`}
					onClick={ () => scrollPrev() }
					/>

				<ArrowRight
					className={`sculpture-images__modal-button sculpture-images__modal-button--next ${ state.currentIndex + 1 === totalImages ? 'disabled' : '' }`}
					onClick={ () => scrollNext() }
				/>
			</div>
		</div>
	)
}